/* scrollbar */
@import 'simplebar-react/dist/simplebar.min.css';

.admin-toolkit-login-button {
    cursor: pointer;
    transition: .3s ease;
    opacity: 1;
}

.admin-toolkit-login-button:hover {
    transition: .3s ease;
    opacity: .75;
}

.admin-toolkit-login-button:active {
    transition: .3s ease;
    opacity: .5;
}

.admin-toolkit-login-button-inactive {
    cursor: not-allowed;
    filter: grayscale(100%);
    opacity: 1;
}